import { Component, Vue, Prop } from 'vue-property-decorator';
import documentos from '@/modules/documentos/services/documentos';
import MountFiltro from '@/components/mountFiltro/mountFiltro.vue';
import utils from '@/utils';
import {
  Icons
} from '@/components/base';
  
@Component({
  components: {
    Icons,
    MountFiltro
  }
})
export default class ListDocumentos extends Vue {
  @Prop({required: true}) idType!: string;

  isLoading = false;
  showFiltro = true;
  list = [];
  pageNow = 1;
  pagination = {
    count: 0,
    countTotal: 0,
    pages: 0,
    per_page: 10,
  };
  query: any = this.getDefaultQuery();

  ajustDate = utils.date.strDatetimeUSAToBR

  mounted() {
    this.getAllItens(this.query);
  }

  getDefaultQuery(){
    return {
      _page: '1',
      _limit: '10',
      status: '1',
      // fkdocumentotipo: 18,
      fkdocumentotipo: this.idType,
    }
  }

  getAllItens(queryObj: any){
    this.isLoading = true;
    const query = utils.queryURL.jsonToURLQuery(queryObj);
    documentos.sendGetDocumentos(query)
      .then( (response) => {
        this.list = response.data.list;
        this.pagination.count = response.data.count;
        this.pagination.countTotal = response.data.totalitems;
        this.pagination.pages = response.data.total;
        this.query = queryObj;
      })
      .catch( () => {
        this.list = [];
      })
      .finally( () => this.isLoading = false );
  }

  clickItemList(documento: any){
    this.$router.push({
      path: '/documento/'+documento.idDocumento,
    });
  }

  changePagination(page: any){
    this.query._page = page;
    window.scrollTo(0,0);
    this.getAllItens(this.query);
  }

  onClickBtnBuscar(filterStr: any, filterObj: any){
    if(Object.keys(filterObj).length == 0){
      this.query = this.getDefaultQuery();
      this.pageNow = 1;
    }
    if(filterObj.datacadastro != undefined){
      filterObj.datacadastro = `%25${filterObj.datacadastro}%25`
    }
    if(filterObj.titulo != undefined){
      filterObj.titulo = `%25${filterObj.titulo}%25`
    }
    else if(this.query.page != '1'){
      this.query._page = '1'
      this.pageNow = 1;
    }
    const query = {...this.query, ...filterObj};
    this.getAllItens(query);
  }

  formFiltroDocumentos = [
    [
      {
        size: 3,
        name: 'datacadastro',
        label: 'Ano',
        type: 'text',
        placeholder: 'Ano',
        isLike: true
      },
      {
        size: 9,
        name: 'titulo',
        label: 'Título',
        type: 'text',
        placeholder: 'Título',
        isLike: true
      }
    ]
  ]

}
  